html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  background: none;
  border: 0;
  box-sizing: border-box;
  padding: 0;
}

textarea:focus, input:focus {
  outline: none;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select::-ms-expand {
  display: none;
}

select {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button {
  background: 0;
  border: 0;
  cursor: pointer;
}

#root {
  color: #F5F5F5;
  font-family: 'Pretendard-Regular';
}

/* Fonts */
@font-face {
  font-family: 'Pretendard-Black';
  src: url('/public/fonts/Pretendard-Black.woff') format('woff');
  font-weight: 800;
}

@font-face {
  font-family: 'Pretendard-Bold';
  src: url('/public/fonts/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('/public/fonts/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Pretendard-Medium';
  src: url('/public/fonts/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('/public/fonts/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'YdestreetB';
  src: url('/public/fonts/YdestreetB.ttf') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'YdestreetL';
  src: url('/public/fonts/YdestreetL.ttf') format('woff');
  font-weight: 300;
}

/*!* slick *!*/
.slick-slide {
  padding-right: 24px;
}
